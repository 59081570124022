import { get, find, isEmpty, isUndefined, identity } from 'lodash';
import { blogAppDefId } from '../../constants/apps';
import FontBuilder from './font-builder';
import defaultStyles from './default-styles';

export default class BlogWidgetBuilder {
  constructor(oldBlogComponent, { widgetId, applicationId, fontMap, styleMap }) {
    this.widgetId = widgetId;
    this.applicationId = applicationId;
    this.oldBlogComponent = oldBlogComponent;
    this.layout = oldBlogComponent.layout;
    this.style = 'tpaw0';
    this.isCustomStyle = false;
    this.properties = {};
    this.propertiesSource = {};
    this.fontMap = fontMap;
    this.styleMap = styleMap;
  }

  mapValueParam(from, to, options = {}) {
    const value = this.resolveValue(from, options);
    if (!isUndefined(value)) {
      this.setValueParam(to, value);
    }
    return this;
  }

  resolveValue(from, options = {}) {
    if (this.shouldSkip(options)) {
      return undefined;
    }
    const [view, fieldId, key] = from.split('/');
    const param = this.findParam({ view, fieldId, key });
    return param ? this.formatValue(param.value, options) : options.defaultValue;
  }

  resolveThemeValue(from, options = {}) {
    if (this.shouldSkip(options)) {
      return undefined;
    }
    const [view, fieldId, key, prop] = from.split('/');
    const param = this.findParam({ view, fieldId, key }) || find(defaultStyles, { view, fieldId, key }) || {};
    const style = get(this.styleMap, param.value);
    const value = get(style, `style.properties.${prop}`);
    return !isUndefined(value) ? this.formatValue(value, options) : options.defaultValue;
  }

  mapLogicParam(from, to, options = {}) {
    let value = get(this.oldBlogComponent, `data.appLogicParams.${from}.value`);
    value = value ? this.formatValue(value, options) : options.defaultValue;
    if (!isUndefined(value)) {
      this.setValueParam(to, value);
    }
    return this;
  }

  shouldSkip({ when }) {
    if (!when) {
      return false;
    }
    const [from, expected] = when;
    return this.resolveValue(from) !== expected;
  }

  findParam(props) {
    return find(get(this.oldBlogComponent, 'data.appLogicCustomizations', []), props);
  }

  formatValue(value, { defaultValue, valueMap, maxValue, formatter = identity } = {}) {
    value = formatter(valueMap ? valueMap[value] || defaultValue : value);
    if (maxValue) {
      value = Math.min(maxValue, value);
    }
    return value;
  }

  mapFont(to) {
    return new FontBuilder(this, to);
  }

  mapThemeColor(from, to, options = {}) {
    const value = this.resolveThemeValue(from, options);
    if (isUndefined(value)) {
      return this;
    }
    const path = from.split('/');
    path[path.length - 1] = `alpha-${path[path.length - 1]}`;
    const opacity = this.resolveThemeValue(path.join('/'), options);
    return this.setColor(to, value, options.opacity || opacity);
  }

  mapThemeParam(from, to, options = {}) {
    const value = this.resolveThemeValue(from, options);
    if (!isUndefined(value)) {
      this.setValueParam(to, value);
    }
    return this;
  }

  mapColor(from, to, options = {}) {
    const value = this.resolveValue(from, options);
    if (isUndefined(value)) {
      return this;
    }
    return this.setColor(to, value, options.opacity);
  }

  setColor(to, value, opacity) {
    if (Array.isArray(value)) {
      [value, opacity] = value;
    }

    if (/color_/.test(value)) {
      this.setThemeParam(to, value.replace('back', ''));
    } else {
      this.setValueParam(to, value);
    }

    if (!isUndefined(opacity)) {
      this.setValueParam(`alpha-${to}`, opacity);
    }

    return this;
  }

  setValueParam(styleParamName, value) {
    return this.setParam(styleParamName, value, 'value');
  }

  setThemeParam(styleParamName, value) {
    return this.setParam(styleParamName, value, 'theme');
  }

  setParam(styleParamName, value, source) {
    this.properties[styleParamName] = value;
    this.propertiesSource[styleParamName] = source;
    if (/^param_color_/.test(styleParamName)) {
      this.propertiesSource[`alpha-${styleParamName}`] = 'value';
    }
    return this;
  }

  build() {
    if (!isEmpty(this.properties)) {
      this.style = {
        type: 'ComponentStyle',
        styleType: 'custom',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
        },
        style: {
          properties: this.properties,
          propertiesSource: this.propertiesSource,
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
      };
    }

    return {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
      componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
      layout: this.layout,
      data: {
        widgetId: this.widgetId,
        applicationId: this.applicationId,
        appDefinitionId: blogAppDefId,
        type: 'TPAWidget',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
        },
      },
      mobileHints: {
        type: 'MobileHints',
        hidden: true,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
        },
      },
      style: this.style,
    };
  }
}
