export const MIGRATION_STATUS_NOT_STARTED = 'none';
export const MIGRATION_STATUS_STARTED = 'started';
export const MIGRATION_STATUS_FINISHED = 'finished';

export const OLD_BLOG_FEED_PAGE_ID = '79f391eb-7dfc-4adf-be6e-64434c4838d9';
export const OLD_BLOG_POST_PAGE_ID = '7326bfbb-4b10-4a8e-84c1-73f776051e10';

export const TOKEN = 'migration-token';

export const OLD_BLOG_APP_PART = {
  ARCHIVE: '56ab6fa4-95ac-4391-9337-6702b8a77011',
  AUTHOR: 'c340212a-6e2e-45cd-9dc4-58d01a5b63a7',
  CATEGORIES: 'categories',
  CUSTOM_FEED: '31c0cede-09db-4ec7-b760-d375d62101e6',
  FEATURED_POSTS: 'c7f57b50-8940-4ff1-83c6-6756d6f0a1f4',
  FEED: '4de5abc5-6da2-4f97-acc3-94bb74285072',
  POSTS_GALLERY: '1b8c501f-ccc2-47e7-952a-47e264752614',
  POSTS_LIST: 'f72fe377-8abc-40f2-8656-89cfe00f3a22',
  SINGLE_POST: 'ea63bc0f-c09f-470c-ac9e-2a408b499f22',
  TAG_CLOUD: 'e000b4bf-9ff1-4e66-a0d3-d4b365ba3af5',
  TICKER: '33a9f5e0-b083-4ccc-b55d-3ca5d241a6eb',
  RELATED_POSTS: 'related-posts',
  HERO_IMAGE: 'hero-image',
};
