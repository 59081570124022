import { get, set, isUndefined, isNil, omitBy } from 'lodash';

const THEME_FONT_KEY_MAP = {
  Title: 'font_0',
  Menu: 'font_1',
  'Page title': 'font_2',
  'Heading XL': 'font_3',
  'Heading L': 'font_4',
  'Heading M': 'font_5',
  'Heading S': 'font_6',
  'Body L': 'font_7',
  'Body M': 'font_8',
  'Body S': 'font_9',
  'Body XS': 'font_10',
};

export default class FontBuilder {
  constructor(widgetBuilder, styleParamName) {
    this.widgetBuilder = widgetBuilder;
    this.styleParamName = styleParamName;
    this.font = {
      preset: 'Custom',
      fontStyleParam: true,
    };
  }

  mapValue(from, targetProp, options) {
    const value = this.widgetBuilder.resolveValue(from, options);
    if (!isUndefined(value)) {
      set(this.font, targetProp, value);
    }
    return this;
  }

  mapTheme(from, options) {
    const value = this.widgetBuilder.resolveValue(from, options);
    const editorKey = get(THEME_FONT_KEY_MAP, value);
    const font = this.widgetBuilder.fontMap[editorKey];
    if (font) {
      this.font.family = font.family;
      this.font.editorKey = editorKey;
      this.font.size = parseInt(font.size, 10);
    }
    return this;
  }

  build() {
    return omitBy(this.font, isNil);
  }

  value() {
    this.widgetBuilder.setValueParam(this.styleParamName, JSON.stringify(this.build()));
    return this.widgetBuilder;
  }
}
