import guidUtils from '@wix/santa-core-utils/dist/cjs/coreUtils/core/guidUtils';
import { TOKEN } from './constants';

export const getPageComponents = async (sdk, pageId) => {
  const children = await sdk.components.getChildren(TOKEN, {
    componentRef: { id: pageId, type: 'DESKTOP' },
  });
  return Promise.all(
    children.map((componentRef) =>
      sdk.components.getType('token', { componentRef }).then((type) => ({ type, componentRef })),
    ),
  );
};

export const getSerializedComponents = async ({ sdk, pageId, predicate = () => true, maintainIdentifiers = true }) => {
  const components = (await getPageComponents(sdk, pageId)).filter(predicate).map(({ componentRef }) => componentRef);
  return Promise.all(
    components.map((componentRef) =>
      sdk.document.components
        .serialize(TOKEN, { componentRef, maintainIdentifiers })
        .then((serialized) => ({ serialized, componentRef, pageId })),
    ),
  );
};

export const getUniqueSectionIdForFeedPage = async (sdk) => {
  for (let i = 0; i < 10; i++) {
    const sectionId = `TPASection_${guidUtils.getUniqueId()}`;
    const isSectionIdTaken = Boolean(await sdk.document.components.getById(TOKEN, { id: sectionId }));
    if (!isSectionIdTaken) {
      return sectionId;
    }
  }

  throw new Error('cannot generate an unique section id');
};
