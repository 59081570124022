import { get, invoke } from 'lodash';
import { getUniqueSectionIdForFeedPage } from './sdk-utils';
import { OLD_BLOG_APP_PART } from './constants';
import { ARCHIVE_WIDGET_ID, CATEGORY_MENU_WIDGET_ID, POST_LIST_WIDGET_ID } from '../../constants/widgets';
import getBox from './get-box';
import BlogWidgetBuilder from './blog-widget-builder';

const boolean = (value) => value === 'true';
let fontMap;
let styleMap;

const mapComponent = async (serializedComponent, { sdk, newBlogFeedComponent, newBlogPostPageComponent, pageId }) => {
  const type = get(serializedComponent, 'data.appPartName');
  const applicationId = newBlogFeedComponent.data.applicationId;

  if (!fontMap) {
    [fontMap, styleMap] = await Promise.all([
      sdk.theme.fonts.getMap(),
      invoke(sdk, 'theme.styles.getAll') || Promise.resolve({}),
    ]);
  }

  switch (type) {
    case OLD_BLOG_APP_PART.FEED:
      const props = {
        // optionalIndex: serializedOldBlogFeedPage.components.findIndex(
        //   (x) => get(x, 'data.appPartName') === OLD_BLOG_APP_PART.FEED,
        // ),
        customId: await getUniqueSectionIdForFeedPage(sdk),
      };
      newBlogFeedComponent.layout = serializedComponent.layout;
      return { componentDefinition: newBlogFeedComponent, props, pageId };
    case OLD_BLOG_APP_PART.SINGLE_POST:
      // TODO:
      // const props = {
      // optionalIndex: serializedOldBlogFeedPage.components.findIndex(
      //   (x) => get(x, 'data.appPartName') === OLD_BLOG_APP_PART.FEED,
      // ),
      // customId: await getUniqueSectionIdForFeedPage(sdk),
      // };
      newBlogPostPageComponent.layout = serializedComponent.layout;
      return { componentDefinition: newBlogPostPageComponent, pageId };
    case OLD_BLOG_APP_PART.CATEGORIES: {
      // prettier-ignore
      const builder = new BlogWidgetBuilder(serializedComponent, { widgetId: CATEGORY_MENU_WIDGET_ID, applicationId, fontMap, styleMap })
        .setValueParam('param_boolean_showAllPostsCategory', false)
        .setValueParam('param_number_textPaddingVertical', 2)
        .setValueParam('param_number_textPaddingHorizontal', 2)
        .mapValueParam('Categories/vars/showPostCount', 'param_boolean_showPostCount', {
          defaultValue: true,
          formatter: boolean,
        })
        .mapValueParam('Categories/vars/alignment', 'param_number_textAlignment', {
          defaultValue: 0,
          valueMap: { left: 0, center: 1, right: 2 },
        })
        .mapFont('param_font_textFont')
          .mapTheme('Categories/category/comp.style')
          .mapValue('Categories/category/comp.fontFamily', 'family')
          .mapValue('Categories/category/comp.fontSize', 'size', { maxValue: 176, formatter: Number })
          .mapValue('Categories/category/comp.bold', 'style.bold', { defaultValue: false, formatter: boolean })
          .mapValue('Categories/category/comp.italic', 'style.italic', { defaultValue: false, formatter: boolean })
          .mapValue('Categories/category/comp.underline', 'style.underline', { defaultValue: false, formatter: boolean })
          .value()
        .setColor('param_color_borderColor', 'color_12', 0)
        .setValueParam('param_number_borderWidth', 0)
        .mapColor('Categories/category/comp.color', 'param_color_textColor')
        .mapColor('Categories/category/comp.backgroundColor', 'param_color_backgroundColor', {
          opacity: 1,
          defaultValue: ['color_21', 0],
        });
      return { componentDefinition: builder.build(), pageId };
    }
    case OLD_BLOG_APP_PART.ARCHIVE: {
      // prettier-ignore
      const builder = new BlogWidgetBuilder(serializedComponent, { widgetId: ARCHIVE_WIDGET_ID, applicationId, fontMap, styleMap })
        .setValueParam('param_number_textPaddingVertical', 0)
        .setValueParam('param_number_textPaddingHorizontal', 0)
        .mapValueParam('ArchiveList/vars/showItemsCount', 'param_boolean_showPostCount', {
          defaultValue: true,
          formatter: boolean,
        })
        .mapValueParam('ArchiveList/vars/monthsToShow', 'param_number_monthsDisplayLimit', {
          defaultValue: 50,
          valueMap: { 'Infinity': 50, '24': 24, '12': 12 },
        })
        .mapFont('param_font_textFont')
          .mapTheme('ArchiveList/date/comp.style')
          .mapValue('ArchiveList/date/comp.fontFamily', 'family')
          .mapValue('ArchiveList/date/comp.fontSize', 'size', { maxValue: 176, formatter: Number })
          .mapValue('ArchiveList/date/comp.bold', 'style.bold', { defaultValue: false, formatter: boolean })
          .mapValue('ArchiveList/date/comp.italic', 'style.italic', { defaultValue: false, formatter: boolean })
          .mapValue('ArchiveList/date/comp.underline', 'style.underline', { defaultValue: false, formatter: boolean })
          .value()
        .setColor('param_color_borderColor', 'color_12', 0)
        .setValueParam('param_number_borderWidth', 0)
        .mapColor('ArchiveList/date/comp.color', 'param_color_textColor')
        .mapColor('ArchiveList/date/comp.backgroundColor', 'param_color_backgroundColor', {
          opacity: 1,
          defaultValue: ['color_21', 0],
        })
        .mapThemeColor('ArchiveList/lineSeparator/comp.style/brd', 'param_color_separatorColor')
        .mapThemeParam('ArchiveList/lineSeparator/comp.style/lnw', 'param_number_separatorWidth', {
          when: ['ArchiveList/itemSeparator/comp.hidden', 'false'],
          formatter: Number,
        });

      return { componentDefinition: builder.build(), pageId };
    }
    case OLD_BLOG_APP_PART.POSTS_LIST: {
      // prettier-ignore
      const builder = new BlogWidgetBuilder(serializedComponent, { widgetId: POST_LIST_WIDGET_ID, applicationId, fontMap, styleMap })
        .mapLogicParam('limit', 'param_number_postListWidgetEntityCount', { defaultValue: 10, formatter: Number });

      return { componentDefinition: builder.build(), pageId };
    }
    case OLD_BLOG_APP_PART.TICKER:
    case OLD_BLOG_APP_PART.TAG_CLOUD:
    default:
      return { componentDefinition: getBox(serializedComponent.layout), pageId };
  }
};

export default mapComponent;
